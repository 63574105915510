<template>
  <div class="createPost-container">
    <el-form ref="postForm" :model="postForm" :rules="rules" class="form-container">
      <sticky :class-name="'sub-navbar '">
        <editor-button :loading="loading" action="save" @click="handleSave" />
        <editor-button :loading="loading" action="saveClose" @click="saveAndClose" />
        <editor-button action="cancel" @click="close" />
      </sticky>
      <div class="createPost-main-container">
        <el-form-item :label="$t('common.name')" prop="name">
          <el-input
            v-model="postForm.name"
            :placeholder="$t('common.name')"
            name="name"
            type="text"
            auto-complete="on"
            disabled="true"
            readonly="true"
            maxlength="64"
          />
          <input-errors :errors="errors.name" />
        </el-form-item>

        <el-form-item :label="$t('common.email')" prop="email">
          <el-input
            v-model="postForm.email"
            :placeholder="$t('common.email')"
            name="email"
            type="text"
            auto-complete="on"
            disabled="true"
            readonly="true"
            maxlength="64"
          />
          <input-errors :errors="errors.email" />
        </el-form-item>

        <el-form-item :label="$t('common.description')" prop="description">
          <el-input
            v-model="postForm.description"
            :placeholder="$t('common.description')"
            name="description"
            type="textarea"
            auto-complete="on"
            maxlength="255"
          />
          <input-errors :errors="errors.description" />
        </el-form-item>

        <el-form-item :label="$t('user.avatar')" prop="description">
          <el-input
            v-model="postForm.avatar"
            :placeholder="$t('user.avatarInfo')"
            name="description"
            type="text"
            auto-complete="on"
          />
          <input-errors :errors="errors.avatar" />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import Sticky from '@/components/Sticky'; // Sticky header-component
import EditorButton from '@/components/crud/EditorButton';
import { closeCurrentTag } from '@/utils/tags';
import InputErrors from '@/components/DSE/InputErrors';
import { handleDatabaseRequestError } from '@/utils/error';
import { focusFirstInputInEl } from '@/utils/index';

const defaultForm = {
  avatar: '',
  description: '',
  email: '',
  name: ''
};

export default {
  name: 'Settings',
  components: {
    Sticky,
    EditorButton,
    InputErrors
  },
  data() {
    const validateRequire = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$i18n.t('validation.required')));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      postForm: Object.assign({}, defaultForm),
      errors: [],
      rules: {
        name: [{ validator: validateRequire }]
      }
    };
  },
  computed: {
    validationErrorInputPrefix() {
      return this.$store.getters['validationErrorInputPrefix'];
    }
  },
  mounted() {
    focusFirstInputInEl(this.$el);
  },
  created() {
    this.postForm.name = this.$store.state.authentication.name;
    this.postForm.email = this.$store.state.authentication.email;
    this.postForm.description = this.$store.state.authentication.description;
    this.postForm.avatar = this.$store.state.authentication.avatar;
    window.addEventListener('beforeunload', this.beforeUnload, false);
    this.$store.dispatch('request/disableErrorCodePrefix', this.validationErrorInputPrefix);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload);
    this.$store.dispatch('request/enableErrorCodePrefix', this.validationErrorInputPrefix);
  },
  methods: {
    beforeUnload(e) {
      if (this.formHasChanged()) {
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    },
    saveData() {
      this.loading = true;
      this.$store
        .dispatch('updateLoggedInUser', this.postForm)
        .then(() => {
          this.$store.dispatch('notify/success', { message: this.$i18n.t('common.data_updated') });
        })
        .catch(error => {
          this.errors = handleDatabaseRequestError(this, error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitForm() {
      this.$refs.postForm.validate(valid => {
        if (valid) {
          this.saveData();
        } else {
          return false;
        }
      });
    },
    formHasChanged() {
      return !!(
        this.postForm.description !== this.$store.state.authentication.description ||
        this.postForm.avatar !== this.$store.state.authentication.avatar
      );
    },
    async handleSave() {
      try {
        await this.$refs.postForm.validate();
      } catch (err) {
        this.$store.dispatch('notify/formValidationFailed');
        return false;
      }

      try {
        await this.saveData();
        return true;
      } catch (error) {
        this.errorMessage = error && error.response && error.response.data;
        this.errorMessage = this.errorMessage || error.message || error || this.$i18n.t('common.data_save_error');
        this.$store.dispatch('notify/error', { message: this.$i18n.t('common.data_save_error') });
        this.actionLoading = false;
        return false;
      }
    },
    async saveAndClose() {
      if (await this.handleSave()) {
        closeCurrentTag(this);
      }
    },
    async close() {
      try {
        if (this.formHasChanged()) {
          await this.$store.dispatch('notify/closeUnsavedConfirm');
        }
        closeCurrentTag(this);
      } catch (err) {
        // do nothing and stay on page
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '~@/styles/mixin.scss';

.createPost-container {
  position: relative;

  .createPost-main-container {
    padding: 40px 45px 20px 50px;

    .postInfo-container {
      position: relative;
      @include clearfix;
      margin-bottom: 10px;

      .postInfo-container-item {
        float: left;
      }
    }
  }
}
</style>
